<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          small
          dark
          @click="openDialog"
        >
          <v-icon left>mdi-plus</v-icon>New Item
        </v-btn>
      </v-col>
    </v-row>
    <!-- start of item dialog -->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>A/R Item Entry
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <item-editor
            :initial="itemRecord"
            :selectedTenant="selectedTenant"
            @itemData="addItem"
            @closeModal="closeDialog"
          ></item-editor>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item dialog -->

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end of snackbar -->
  </div>
</template>

<script>
import ItemEditor from "./item-editor.vue";
export default {
  components: {
    ItemEditor,
  },
  props: {
    selectedTenant: {
      type: Object,
    },
  },
  computed: {},
  data() {
    return {
      dialog: false,
      itemRecord: {},
    };
  },
  methods: {
    addItem(data) {
      this.$emit("itemData", data);
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog(falseValue) {
      this.dialog = falseValue;
      this.$refs.snackbar.show("Please Select Customer!", "red");
    },
  },
  created() {},
};
</script>

<style scoped>
</style>